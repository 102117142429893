import SA from 'scroll-animations-js';
import $ from "jquery";
import AOS from 'aos';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Player from '@vimeo/player';
import "jquery-mask-plugin";
import {traduzir, traduzirTeaser} from "./traducao";

SA.init();

window.onload = function () {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};

function wait_for_image_load_then(function_to_be_called) {
    new Promise((resolve, reject) => {
        setTimeout(
            () => {
                // selects all images, adjust according to need
                let images = document.querySelectorAll("img");
                // checks if all image is completey loaded
                // returns true only if every element in the array is true
                let all_complete = Array.from(images).every((img) => img.complete)
                if (all_complete) {
                    resolve('images load finish');
                } else {
                    reject('image loading');
                }
            },
            Math.random() + 10 // necessary to make each Promise a new instance
        )
    }).then(
        // if resolve is returned in promise
        (res) => {
            console.log(res);
            function_to_be_called();
        }
    ).catch(
        // if reject is returned in promise
        (reason) => {
            console.log(reason);
            wait_for_image_load_then(function_to_be_called);
        }
    );
}

function function_after_image_is_loaded() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
    $(document).ready(function () {
        if ($("body").hasClass("teaser")) {
            if ($(window).innerWidth() > 890) {


                gsap.registerPlugin(ScrollTrigger);
                gsap.to(".col1", {
                    scrollTrigger: {
                        trigger: ".col1",
                        scrub: 1,
                        markers: false,
                        start: "100%",
                        toggleClass: 'enable',
                        end: "300%"
                    },
                    onStart: () => {
                        console.log("test");
                        $("#video-mp4-muted").trigger("play");
                    },
                    x: -$(".col1").innerWidth()
                })
                gsap.to(".content-col1", {
                    scrollTrigger: {
                        trigger: ".content-col1",
                        scrub: 1,
                        markers: false,
                        start: "100%",
                        end: "300%"
                    },
                    x: $(".content-col1").innerWidth()
                });
                gsap.to(".col2", {
                    scrollTrigger: {
                        trigger: ".col2",
                        scrub: 1,
                        markers: false,
                        start: "100%",
                        toggleClass: 'enable',
                        end: "300%"
                    },
                    x: $(".col2").innerWidth()
                });
                gsap.to(".content-col2", {
                    scrollTrigger: {
                        trigger: ".content-col2",
                        scrub: 1,
                        markers: false,
                        start: "100%",
                        end: "300%"
                    },
                    x: -$(".content-col2").innerWidth()
                })
            } else {
                gsap.registerPlugin(ScrollTrigger);
                gsap.to(".col1", {
                    scrollTrigger: {
                        trigger: ".col1",
                        scrub: 1,
                        markers: false,
                        start: "0%",
                        toggleClass: 'enable',
                        end: "200%"
                    },
                    y: -$(".col1").innerHeight()
                });
                gsap.to(".content-col1", {
                    scrollTrigger: {
                        trigger: ".content-col1",
                        scrub: 1,
                        markers: false,
                        start: "0%",
                        end: "200%"
                    },
                    y: $(".content-col1").innerHeight()
                });
                /*gsap.to(".col2", {
                    scrollTrigger: {
                        trigger: ".col2",
                        scrub: 1,
                        markers: false,
                        start: "200%",
                        toggleClass: 'enable',
                        end: "400%"
                    },
                    y: -$(".col2").innerHeight()
                }); */
                /* gsap.to(".content-col2", {
                     scrollTrigger: {
                         trigger: ".content-col2",
                         scrub: 1,
                         markers: false,
                         start: "200%",
                         end: "400%",
                     },
                     onStart: () => {
                         console.log("test");
                         $("#video-mp4-muted").trigger("play");
                     },
                     y: $(".content-col2").innerHeight()
                 }) */
            }
        }

        if ($("body").hasClass("pre-teaser")) {
            if ($(window).innerWidth() > 768) {
                var player = new Player("vimeo01", {
                    id: "https://player.vimeo.com/video/932466450?h=0c48115b10",
                    autoplayButton: "play",
                    controls: true,
                });
                $("#play").click(function () {
                    player.setMuted(false)
                    player.play();
                    $("#play").fadeOut();
                    return false;
                });
                gsap.registerPlugin(ScrollTrigger);
                gsap.to("#vimeo01", {
                    scrollTrigger: {
                        trigger: "#vimeo01",
                        scrub: 1,
                        markers: false,
                        start: "50% center",
                        end: "200% center",
                        onUpdate: (timeline) => {
                            if (timeline.progress > 0.3) {
                                $(".video-as").addClass("act");
                            } else {
                                $(".video-as").removeClass("act");
                            }
                            console.log(timeline.progress); //this works
                        }
                    },
                    onStart: (val) => {
                        //$(".video-as").removeClass("act");
                    },
                    onUpdate: (val) => {
                        console.log(val);
                    },
                    onComplete: () => {
                        //$(".video-as").addClass("act");
                    },
                    scale: 1
                });
            }
        }
    })
}

wait_for_image_load_then(function_after_image_is_loaded);

$("#familias").click(function (event) {


    var screenWidth = $(window).width();
    var clickX = event.pageX;
    var screenCenter = screenWidth / 2;
    var oneThirdWidth = screenWidth / 3;

    if (clickX < (screenCenter - oneThirdWidth)) {
        console.log("teste");
        if (!document.getElementById("video-mp4-muted").paused) {
            document.getElementById("video-mp4-muted").pause()
            $(".paused").hide();
            $(".playing").show();
        } else {
            document.getElementById("video-mp4-muted").play()
            $(".paused").show();
            $(".playing").hide();

        }
    } else {
        if ($("#video-mp4-muted").prop("muted"))
            $("#video-mp4-muted").prop("muted", 0);
        else
            $("#video-mp4-muted").prop("muted", 1);
    }


})


if ($("body").hasClass("teaser")) {
    var player = new Player("vimeo01", {
        id: "https://player.vimeo.com/video/932466450?h=0c48115b10",
        autoplayButton: "play",
        controls: true,
    });
    $("#play").click(function () {
        player.setMuted(false)
        player.play();
        $("#play").fadeOut();
        return false;
    });
    gsap.registerPlugin(ScrollTrigger);
    gsap.to("#vimeo01", {
        scrollTrigger: {
            trigger: "#vimeo01",
            scrub: 1,
            markers: false,
            start: "50% center",
            end: "200% center",
            onUpdate: (timeline) => {
                if (timeline.progress > 0.3) {
                    $(".video-as").addClass("act");
                } else {
                    $(".video-as").removeClass("act");
                }
                console.log(timeline.progress); //this works
            }
        },
        onStart: (val) => {
            //$(".video-as").removeClass("act");
        },
        onUpdate: (val) => {
            console.log(val);
        },
        onComplete: () => {
            //$(".video-as").addClass("act");
        },
        scale: 1
    });
    console.log("test")
}


/*gsap.to(".cont", {
    scrollTrigger: {
        trigger:".cont svg",
        markers:true,
        start: $(".neg").offset().top,
        toggleClass: 'enable',
        end:$(".neg").offset().top + ($(".col2").innerHeight() * 2.5)
    },
    opacity:0
}); */


function familyBind(firstElement, lastElement) {
    var familyElement = document.getElementById(firstElement);
    var video = document.getElementById(lastElement);

    var firstElementHeight = familyElement.offsetHeight;
    var firstElementBottom = familyElement.offsetTop + 2 * (firstElementHeight);

    var scrollTop = window.pageYOffset;

    var overlap = scrollTop - firstElementBottom;

    if (scrollTop > firstElementBottom) {
        if (scrollTop < (firstElementBottom + familyElement.offsetHeight)) {
            video.style.transform = 'translateY(-' + overlap + 'px)';
        } else {
            video.style.transform = 'translateY(-' + familyElement.offsetHeight + 'px)';
        }
    } else {
        video.style.transform = 'translateY(0px)';
    }
}

function familyBind2(firstElement, lastElement) {
    var familyElement = document.getElementById(firstElement);
    var video = document.getElementById(lastElement);

    var firstElementHeight = familyElement.offsetHeight;
    var firstElementBottom = familyElement.offsetTop + 2 * (firstElementHeight);

    var scrollTop = window.pageYOffset;

    var overlap = scrollTop - firstElementBottom;

    if (scrollTop > firstElementBottom) {
        if (scrollTop < (firstElementBottom + familyElement.offsetHeight)) {
            video.style.transform = 'translateY(-' + overlap + 'px)';
        } else {
            video.style.transform = 'translateY(-' + familyElement.offsetHeight + 'px)';
        }
    } else {
        video.style.transform = 'translateY(0px)';
    }
}

if ($(window).innerWidth() > 768) {
    /*familyBind('family', 'video');*/
} else {
    /*familyBind('first', 'second');
    familyBind2('second', 'video');
    */
}
window.addEventListener('scroll', function () {
    if ($(window).innerWidth() > 768) {
        //familyBind('family', 'video');
    } else {
        window.addEventListener('scroll', function () {
            // familyBind('first', 'second');
            //familyBind2('second', 'video');
        });
    }
});

window.addEventListener('resize', function () {
    ScrollTrigger.update();
})
window.addEventListener('scroll', function () {
    header();
});

function header() {
    //var elemento = document.getElementById('mid');
    var header = document.getElementById('form-top');
    //var elementoTopo = elemento.offsetTop;
    var scrollAtual = window.scrollY;
    //var height = window.innerHeight;

    if (scrollAtual !== 0) {
        $("header").addClass("act");
        // Faça o que você precisa quando o scroll !assar do topo do elemento aqui
    } else {
        $("header").removeClass("act");
    }
}

$(".menuMobile").click(function () {
    $('.menu').toggleClass('act');
    $('header').toggleClass('actMenu');
    return false;
})

$('.menu a').click(function () {
    $('.menu').removeClass('act');
});

$('.menu a').click(function () {
    $('.menu').removeClass('act');
});

$(".whatsapp").click(function () {
    $(".float_modal").fadeIn();
    return true;
})

$(".float_modal .close").click(function () {
    $(".float_modal").fadeOut();
    return false;
})

$("input[type=\"tel\"]").mask('+55 (00) 00000-0000');
$(document).ready(function () {
    //header();
})

//ScreenOrientation.lock("portrait");
gsap.registerPlugin(ScrollTrigger);
gsap.to(".line-1 span", {
    scrollTrigger: {
        trigger: ".topo-maringa",
        scrub: 1,
        markers: false,
        start: "0%",
        end: "100%"
    },
    x: $(".line-1 span").innerWidth()
})


const videomp4 = document.getElementById('video-mp4');
const playButton = document.getElementById('play-mp4');
const sectionMp4 = document.getElementById('section-mp4');
playButton.addEventListener('click', () => {
    videomp4.play();
    $("#section-mp4").addClass("act");
    if (videomp4.requestFullscreen) {
        videomp4.requestFullscreen();
    } else if (videomp4.mozRequestFullScreen) {
        videomp4.mozRequestFullScreen();
    } else if (videomp4.webkitRequestFullscreen) {
        videomp4.webkitRequestFullscreen();
    } else if (videomp4.msRequestFullscreen) {
        videomp4.msRequestFullscreen();
    }

    if (videomp4.requestFullscreen) {
        videomp4.requestFullscreen();
    } else if (videomp4.mozRequestFullScreen) {
        videomp4.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
        videomp4.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) { /* IE/Edge */
        videomp4.msRequestFullscreen();
    }
});
document.addEventListener('fullscreenchange', () => {
    if (!document.fullscreenElement) {
        $("#section-mp4").removeClass("act");
        videomp4.pause();
    }
});

const videomp42 = document.getElementById('video-mp4-2');
const playButton2 = document.getElementById('play-mp4-2');
const sectionMp24 = document.getElementById('section-mp4-2');
if(playButton2)
playButton2.addEventListener('click', () => {
    videomp42.play();
    $("#section-mp4").addClass("act");
    if (videomp42.requestFullscreen) {
        videomp42.requestFullscreen();
    } else if (videomp42.mozRequestFullScreen) {
        videomp42.mozRequestFullScreen();
    } else if (videomp42.webkitRequestFullscreen) {
        videomp42.webkitRequestFullscreen();
    } else if (videomp42.msRequestFullscreen) {
        videomp42.msRequestFullscreen();
    }

    if (videomp42.requestFullscreen) {
        videomp42.requestFullscreen();
    } else if (videomp42.mozRequestFullScreen) {
        videomp42.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
        videomp42.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) { /* IE/Edge */
        videomp42.msRequestFullscreen();
    }
});
document.addEventListener('fullscreenchange', () => {
    if (!document.fullscreenElement) {
        $("#section-mp4-2").removeClass("act");
        videomp42.pause();
    }
});


$('a[href="#en"]').click(function () {
    if ($("body").hasClass("pre-teaser"))
        traduzir("en");

    if ($("body").hasClass("teaser"))
        traduzirTeaser("en");

    $(".traducao  .submenu > a").show();
    $(this).hide();
    return false;
})

$('a[href="#pt"]').click(function () {
    if ($("body").hasClass("pre-teaser"))
        traduzir("pt");

    if ($("body").hasClass("teaser"))
        traduzirTeaser("pt");
    $(".traducao  .submenu > a").show();
    $(this).hide();
    return false;
});

$('a[href="#es"]').click(function () {
    if ($("body").hasClass("pre-teaser"))
        traduzir("es");

    if ($("body").hasClass("teaser"))
        traduzirTeaser("es");

    $(".traducao .submenu > a").show();
    $(this).hide();
    return false;
})