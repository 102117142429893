import $ from "jquery";

const en = {
    "trad1": "UNION",
    "trad2": "CONTACT",
    "trad3": "<strong>AN INTERNATIONAL ICON</strong>,<br> BORN FROM A MEETING<br> BETWEEN TWO FAMILIES<span>Coming soon to Maringá</span>",
    "trad4": "Would you like to receive exclusive information?",
    "trad5": "Full name",
    "trad6": "Email address",
    "trad7": "Area code + phone number",
    "trad8": "I declare that I have read and accept the terms of the Plaenge Group’s <a href='https://www.grupoplaenge.com.br/politica-de-privacidade/' target='_blank'>privacy policy</a>",
    "trad9": "I agree to receive communications and be contacted about possible services",
    "trad10": "Send",
    "trad11": "Italian design, Brazilian heart.",
    "trad12": "<p><strong>Plaenge</strong>, who are committed to designing a unique narrative for every development they agree to, has " +
        "formed an alliance with <strong>Pininfarina</strong>, global icon of Italian style recognized for its unparalleled ability to " +
        "create timeless beauty with design projects spanning several industries.</p>" +
        "<p>Each one has developed a reputation for excellence in their own territories over the decades. Plaenge " +
        "in Brazil and Chile, Pininfarina in Italy, with offices in the United States, China and Germany, both " +
        "driven on by visionary leaders. They have now joined forces to start a new project, after specializing in " +
        "design and luxury for almost a century since they were founded.</p>" +
        "<p>This union brings to life a project that combines the innovation of <strong>Italian design</strong> and <strong>Brazilian architecture</strong>." +
        "As well as meshing together the different visions and talent available, their design reverberates with the " +
        "legacy of their values.</p>" +
        "<p>It is a new milestone in the world of luxury design.</p>",
    "trad13": "Find out more"
}

const es = {
    "trad1": "ENCUENTRO",
    "trad2": "CONTACTO",
    "trad3": "DEL ENCUENTRO ENTRE DOS FAMILIAS,<br> <strong>NACE UN ÍCONO INTERNACIONAL</strong><span>Próximamente en Maringá</span>",
    "trad4": "¿Quieres recibir informaciones exclusivas?",
    "trad5": "Nombre completo",
    "trad6": "Correo electrónico",
    "trad7": "DDD+Teléfono",
    "trad8": "Declaro que he leído y que acepto los términos de la <a href='https://www.grupoplaenge.com.br/politica-de-privacidade/' target='_blank'>política de privacidad</a> del grupo Plaenge",
    "trad9": "Estoy de acuerdo en recibir comunicaciones y ser contactado para una posible asistencia",
    "trad10": "Enviar",
    "trad11": "Diseño Italiano, alma brasileña.",
    "trad12": "<p></p>" +
        "<p>Impulsadas por líderes visionarios, a lo largo de varias décadas, cada una construyó una reputación\n" +
        "de excelencia en su territorio. Plaenge en Brasil y Chile, Pininfarina en Italia, con oficinas en Estados\n" +
        "Unidos, China y Alemania. Envueltas por el diseño y el lujo, ahora, casi un siglo después de sus\n" +
        "orígenes, unen fuerzas en un nuevo proyecto.</p>" +
        "<p>Este encuentro da vida a un proyecto que combina la innovación del <strong>diseño italiano</strong> con la <strong>arquitectura brasileña</strong>. Además de mezclar visiones y talentos, el legado de sus valores reverbera en el diseño.</p>" +
        "<p>Nace un hito en el mundo del diseño de lujo.</p>",
    "trad13": "Obtenga más informaciones"
}

const enTeaser = {
    "trad1": "UNION",
    "trad2": "BRANDS",
    "trad3": "DESIGN",
    "trad31": "CONTACT",
    "trad32": "WHERE <strong>LUXURY</strong> AND <strong>DESIGN</strong> MEET.",
    "trad33": "From two powerhouses, an <strong>icon is born.</strong>",
    "trad34": "A design company with a global reputation that specializes in creating complex, bold designs in everything\n" +
        "it does. The brand brings its experience of automotive design to projects that have revolutionized the\n" +
        "market for many decades.",
    "trad35" :"We have over 50 years of experience and are committed to designing a unique narrative for every\n" +
        "development we agree to. We have a reputation for building developments to the same exacting standards\n" +
        "as good tailoring.",
    "trad4": "Would you like to receive exclusive information?",
    "trad5": "Full name",
    "trad6": "Email address",
    "trad7": "Area code + phone number",
    "trad8": "I declare that I have read and accept the terms of the Plaenge Group’s <a href='https://www.grupoplaenge.com.br/politica-de-privacidade/' target='_blank'>privacy policy</a>",
    "trad9": "I agree to receive communications and be contacted about possible services",
    "trad10": "Send",
    "trad11": "Italian design, Brazilian heart.",
    "trad12": "<p>Plaenge, who are committed to designing a unique narrative for every development\n" +
        "they agree to, has formed a strategic partnership with Pininfarina, global icon of\n" +
        "Italian style recognized for its unparalleled ability to create timeless beauty with design\n" +
        "projects spanning several industries.</p>" +
        "<p>Each one has developed a reputation for excellence in their own territories over the\n" +
        "decades. Plaenge in Brazil and Chile, Pininfarina in Italy, with offices in the United\n" +
        "States, China and Germany, both driven on by visionary leaders. They have now joined\n" +
        "forces to start a new moment, after specializing in design and luxury for almost a\n" +
        "century since they were founded.</p>" +
        "<p>This partnership brings to life a collection of projects that combines the innovation of\n" +
        "Italian design and Brazilian architecture. As well as meshing together the different\n" +
        "visions and talent available, their design reverberates with the legacy of their values.</p>" +
        "<p>It is a new milestone in the world of luxury design.</p>",
    "trad13": "Find out more",
    "trad14": "“We are proud to know that we can build on our experience of high-standard projects in Brazil with a great\n" +
        "partnership that will go down in history.”",
    "trad15" : "Director at Plaenge",
    "trad16" : "“We are delighted with this project and confident that it will be the beginning of a long-term partnership.”",
    "trad17" : "CEO of PININFARINA",
    "trad18" : "Project",
    "trad19" : "Pininfarina&#39;s design embraces Brazil&#39;s rich architectural heritage and blends this with the timeless elegance\n" +
        "of Italian design. This fusion between tradition and modernity results in shapes that reflect the coming\n" +
        "together of Plaenge and Pininfarina.",
    "trad20" : "Contact us",
}

const esTeaser = {
    "trad1": "ENCUENTRO",
    "trad2": "MARCAS",
    "trad3": "PROYECTO",
    "trad31": "CONTACTO",
    "trad32": "EL ENCUENTRO DEL <strong>LUJO</strong> CON EL <strong>DISEÑO</strong>",
    "trad33": "De dos potencias, <strong>nace un ícono</strong>",
    "trad34": "Referencia mundial de Diseño, especialista en combinar complejidad y audacia en todo lo que crea. Trae su herencia del diseño de automóviles para marcas de lujo en proyectos que revolucionaron el mercado a lo largo de décadas.",
    "trad35" :"Con más de 50 años de actuación, su motivación es diseñar historias únicas para cada proyecto que crea\n" +
        "y es reconocida por construir emprendimientos con el esmero de un buen sastre.",
    "trad4": "¿Quieres recibir informaciones exclusivas?",
    "trad5": "Nombre completo",
    "trad6": "Correo electrónico",
    "trad7": "DDD+Teléfono",
    "trad8": "Declaro que he leído y que acepto los términos de la <a href='https://www.grupoplaenge.com.br/politica-de-privacidade/' target='_blank'>política de privacidad</a> del grupo Plaenge",
    "trad9": "Estoy de acuerdo en recibir comunicaciones y ser contactado para una posible asistencia",
    "trad10": "Enviar",
    "trad11": "Diseño Italiano, alma brasileña.",
    "trad12": "<p>Plaenge, motivada por el deseo de diseñar historias únicas para cada emprendimiento\n" +
        "que firma, establece una asociación estratégica con Pininfarina, icono mundial del\n" +
        "estilo Italiano, reconocida por su incomparable capacidad para crear belleza atemporal\n" +
        "con proyectos de diseño que abarcan diversos sectores.</p>" +
        "<p>Impulsadas por líderes visionarios, a lo largo de varias décadas, cada una construyó\n" +
        "una reputación de excelencia en su territorio. Plaenge en Brasil y Chile, Pininfarina en\n" +
        "Italia, con oficinas en Estados Unidos, China y Alemania. Envueltas por el diseño y el\n" +
        "lujo, ahora, casi un siglo después de sus orígenes, unen fuerzas en un nuevo" +
        "momento.</p>" +
        "<p>Esta asociación da vida a una colección de proyectos que combina la innovación\n" +
        "del diseño italiano con la arquitectura brasileña. Además de mezclar visiones y\n" +
        "talentos, el legado de sus valores reverbera en el diseño.</p>" +
        "<p>Nace un hito en el mundo del diseño de lujo.</p>",
    "trad13": "Obtenga más informaciones",
    "trad14": "“Nos enorgullece saber que nuestras experiencia en proyectos de alto nivel en Brasil se consolidarán con\n" +
        "esta gran alianza que pasará a la historia”",
    "trad15" : "Director de Plaenge",
    "trad16" : "“Estamos contentos y seguros de que este proyecto marcará el inicio de una colaboración a largo plazo.”",
    "trad17" : "CEO DE PININFARINA",
    "trad18" : "Proyecto",
    "trad19" : "El diseño de Pininfarina abraza el rico patrimonio arquitectónico de Brasil y lo fusiona con la elegancia\n" +
        "atemporal del diseño Italiano. Esta unión entre tradición y modernidad da como resultado las formas que\n" +
        "marcan el encuentro entre Plaenge y Pininfarina.",
    "trad20" : "Entre en contacto",
}

let pt = {}

let initial = false;

$(document).ready(function () {
    if($("body").hasClass("teaser")) {
        Object.keys(enTeaser).forEach((z) => {
            pt[z] = $('[data-lang="' + z + '"]').html() ? $('[data-lang="' + z + '"]').html() : $('[data-lang="' + z + '"]').attr("placeholder");
        })
    } else {
        Object.keys(en).forEach((z) => {
            pt[z] = $('[data-lang="' + z + '"]').html() ? $('[data-lang="' + z + '"]').html() : $('[data-lang="' + z + '"]').attr("placeholder");
        })
    }

})

export function traduzir(lang = "en") {
    $(".traducao > a > span").text(lang.toUpperCase());

    if(lang === "en") {
        Object.keys(en).forEach((z) => {
            $('*[data-lang="' + z + '"]:not("input")').html(en[z]);
            $('input[data-lang="' + z + '"]').attr("placeholder", en[z]);
        })
    }

    if(lang === "es") {
        Object.keys(es).forEach((z) => {
            $('*[data-lang="' + z + '"]:not("input")').html(es[z]);
            $('input[data-lang="' + z + '"]').attr("placeholder", es[z]);
        })
    }

    if(lang === "pt") {
        Object.keys(pt).forEach((z) => {
            $('*[data-lang="' + z + '"]:not("input")').html(pt[z]);
            $('input[data-lang="' + z + '"]').attr("placeholder", pt[z]);
        })
    }
}


export function traduzirTeaser(lang = "en") {
    $(".traducao > a > span").text(lang.toUpperCase());

    if(lang === "en") {
        Object.keys(enTeaser).forEach((z) => {
            $('*[data-lang="' + z + '"]:not("input")').html(enTeaser[z]);
            $('input[data-lang="' + z + '"]').attr("placeholder", enTeaser[z]);
        })
    }

    if(lang === "es") {
        Object.keys(esTeaser).forEach((z) => {
            $('*[data-lang="' + z + '"]:not("input")').html(esTeaser[z]);
            $('input[data-lang="' + z + '"]').attr("placeholder", esTeaser[z]);
        })
    }

    if(lang === "pt") {
        Object.keys(pt).forEach((z) => {
            $('*[data-lang="' + z + '"]:not("input")').html(pt[z]);
            $('input[data-lang="' + z + '"]').attr("placeholder", pt[z]);
        })
    }
}
export default {traduzir, traduzirTeaser};